import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import api from '../../services/api';
import "../../utils/axiosInterceptor";
import './styles.css';
import { useEffect } from 'react';
import { AiFillPlusCircle } from 'react-icons/ai'
import Cookies from 'js-cookie';
import DatePicker from 'react-date-picker';
import { checkCourseInput } from '../../Validate/checkCourse';
import { toast } from 'react-toastify';
import Loader from 'react-loader-spinner';


export default function RegisterCourse(props) {
    const course_id = props.course_id;
    const user = Cookies.get('User') ? JSON.parse(Cookies.get('User')) : null;
    const isAdmin = user.isAdmin;
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [beginning_date, setBeginning_date] = useState(new Date());
    const [ending_date, setEnding_date] = useState(new Date());
    const [hasEndingDate, setHasEndingDate] = useState(false);
    const [responsibleBody, setResponsibleBody] = useState('');
    const [participant_workload, setParticipant_workload] = useState('');
    const [hasTopics, setHasTopics] = useState(false);
    const [topics, setTopics] = useState(['']);
    const [lineForEachTopic, setLineForEachTopic] = useState([0]);
    const [speaker, setSpeaker] = useState('');
    const [logoOptions, setLogoOptions] = useState([]);
    const [signatureOptions, setSignatureOptions] = useState([]);
    const [logos, setLogos] = useState([null, null, null, null]);
    const [signatures, setSignatures] = useState([null, null, null, null]);
    const [logoCounter, setLogoCounter] = useState(1);
    const [signatureCounter, setSignatureCounter] = useState(1);
    const [topicsCounter, setTopicsCounter] = useState(1);
    const [topicsLineCounter, setTopicsLineCounter] = useState(0);
    const [loading, setLoading] = useState(true);
    const history = useHistory();


    //Campos para adicionar novos logos e assinaturas
    let logoFields = [];
    let signatureFields = [];
    let topicFields = [];

    function checkLine(index, newValue) {
        let aux = [...lineForEachTopic];
        let previusValue = aux[index] ? aux[index] : 0;
        if (newValue.length !== 0)
            aux[index] = Math.floor((newValue.length - 1) / 51) + 1;
        else
            aux[index] = 0;


        if (aux[index] !== lineForEachTopic[index]) {
            setTopicsLineCounter(topicsLineCounter + (aux[index] - previusValue));
        }
        setLineForEachTopic(aux);
        setTopics([...topics.slice(0, index), ...[newValue], ...topics.slice(index + 1, topics.length)])
    }

    //Conta quantos campos deve renderizar (de acordo com os contadores) e os adiciona às variaveis acima
    if (!loading) {
        for (let i = 0; i < logoCounter; i++) {
            logoFields.push(<div key={'logoField' + i} className="plusFields">
                <select className="selectpicker" onChange={e => {
                    setLogos([...logos.slice(0, i), ...[parseInt(e.target.value)], ...logos.slice(i + 1, logos.length)])
                }}>
                    <option value={null}>Nenhum</option>
                    {logoOptions.map(logo => {
                        return (
                            <option key={'optionLogo' + logo.id} selected={course_id ? (logos[i] === logo.id ? "SELECTED" : '') : ''} value={logo.id}>{logo.name}</option>
                        )
                    })}
                </select>
            </div>
            )
        }
        for (let i = 0; i < signatureCounter; i++) {
            signatureFields.push(<div key={'signatureField' + i} className="plusFields">
                <select className="selectpicker" onChange={e => {
                    setSignatures([...signatures.slice(0, i), ...[parseInt(e.target.value)], ...signatures.slice(i + 1, signatures.length)])
                }}>
                    <option value={null}>Nenhuma</option>
                    {signatureOptions.map(signature => {
                        return (
                            <option key={'optionSignature' + signatureOptions.id} selected={course_id ? (signatures[i] === signature.id ? "SELECTED" : '') : ''} value={signature.id}>{signature.name}</option>
                        )
                    })}
                </select>
            </div>
            )
        }

        for (let i = 0; i < topicsCounter; i++) {
            topicFields.push(<div key={'topicField' + i}>
                <input disabled={!isAdmin} className="topicsInput" type="text" value={topics[i]}
                    onChange={e => checkLine(i, e.target.value)} />
            </div>
            )
        }
    }

    function addTopic() {
        let auxTopics = [...topics];
        let auxCounter = [...lineForEachTopic];
        auxTopics.push('');
        auxCounter.push(0);
        setTopics(auxTopics);
        setLineForEachTopic(auxCounter);
        setTopicsCounter(topicsCounter + 1)
    }

    useEffect(() => {
        if (!hasEndingDate)
            setEnding_date(null)
    }, [hasEndingDate])

    //Esta rota tambem pode ser utilizada para editar um curso.
    //Portanto, se for passado para rota um user_id, as informações do curso
    //serao buscadas e editadas
    useEffect(() => {
        setLoading(true);
        if (course_id) {
            api.get(`/course/${course_id}`)
                .then(response => {
                    const course = response.data.course;
                    setName(course.name);
                    setDescription(course.description);
                    setBeginning_date(course.beginning_date);
                    setHasEndingDate(course.ending_date !== null)
                    setEnding_date(course.ending_date);
                    setResponsibleBody(course.responsibleBody);
                    setParticipant_workload(String(course.participant_workload));
                    if (course.topics) {
                        setHasTopics(true);
                        setTopics(course.topics.split('\n'));
                        setTopicsCounter(course.topics.split('\n').length)
                        let lineForEachTopicAux = [];
                        let linesCounter = 0;
                        for (let i = 0; i < course.topics.split('\n').length; i++) {
                            const lines = Math.floor((course.topics.split('\n')[i].length - 1) / 51) + 1;
                            lineForEachTopicAux.push(lines);
                            linesCounter += lines;
                        }
                        setLineForEachTopic(lineForEachTopicAux);
                        setTopicsLineCounter(linesCounter);

                    }
                    else
                        setHasTopics(false);
                    setLogos([course.logo1_id, course.logo2_id, course.logo3_id, course.logo4_id]);
                    setSignatures([course.signature1_id, course.signature2_id, course.signature3_id, course.signature4_id]);
                    setLogoCounter(!!course.logo1_id + !!course.logo2_id + !!course.logo3_id + !!course.logo4_id)
                    setSignatureCounter(!!course.signature1_id + !!course.signature2_id + !!course.signature3_id + !!course.signature4_id)
                    api.get(`/courseSpeaker/${course.id}`)
                        .then(response2 => {
                            setSpeaker([...response2.data.speakers]);
                            setLoading(false);
                            if (isAdmin) {
                                setLoading(true);
                                api.get("/logo")
                                    .then(response => {
                                        setLogoOptions(response.data.logos);
                                        api.get("/signature")
                                            .then(response2 => {
                                                setSignatureOptions(response2.data.signatures);
                                                setLoading(false);
                                            })
                                    })
                            }
                        })
                })
        }
        else {
            if (isAdmin) {
                setLoading(true);
                api.get("/logo")
                    .then(response => {
                        setLogoOptions(response.data.logos);
                        api.get("/signature")
                            .then(response2 => {
                                setSignatureOptions(response2.data.signatures);
                                setLoading(false);
                            })
                    })
            }
        }
    }, [course_id, isAdmin])

    function register(e) {
        e.preventDefault();

        let topicsHelper = '';
        if (hasTopics) {
            topicsHelper = topics[0];
            for (let i = 1; i < topicsCounter; i++) {
                if (topics[i])
                    topicsHelper += '\n' + topics[i];
            }
        }

        if (course_id) {
            const data = {
                name,
                description,
                beginning_date,
                ending_date,
                responsibleBody,
                participant_workload: participant_workload ? participant_workload : 0,
                logos,
                signatures,
                course_id,
                topics: topicsHelper
            }

            let error = checkCourseInput(data, topicsLineCounter);

            if (error.length === 0) {

                api.put('/course', data)
                    .then(() => {
                        toast.success('Curso alterado com sucesso', {
                            position: "top-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                        });
                    })
            } else {
                error.map(err => (
                    toast.warn(err, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                    })
                ));
            }
        } else {
            const data = {
                name,
                description,
                beginning_date,
                ending_date: new Date() === ending_date ? null : ending_date,
                responsibleBody,
                participant_workload: participant_workload ? participant_workload : 0,
                logos,
                signatures,
                topics: topicsHelper
            }

            let error = checkCourseInput(data, topicsLineCounter);

            if (error.length === 0) {
                api.post('/course', data)
                    .then((response) => {
                        toast.success('Curso cadastrado com sucesso', {
                            position: "top-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: false,
                            progress: undefined,
                        });
                        setName('');
                        setDescription('');
                        setBeginning_date(new Date());
                        setEnding_date(new Date());
                        setResponsibleBody('');
                        setParticipant_workload('');
                        setHasTopics(false);
                        setTopics(['']);
                        setLineForEachTopic([0]);
                        setLogos([logos[0], null, null, null]);
                        setSignatures([signatures[0], null, null, null]);
                        setLogoCounter(1);
                        setSignatureCounter(1);
                        setTopicsCounter(1);
                        setTopicsLineCounter(0);
                        window.scrollTo(0, 0);
                    })
                    .catch(err => {
                        console.log("erro: " + err.message);
                        
                        if (err.response.status === 401)
                            toast.error('Um curso com este nome já existe', {
                                position: "top-center",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: false,
                                progress: undefined,
                            });
                        else
                            toast.error(`Preencha todas as informações necessárias`, {
                                position: "top-center",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: false,
                                progress: undefined,
                            });
                    })
            } else {
                error.map(err => (
                    toast.warn(err, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                    })
                ));
            }
        }


    }

    function deleteCourse(e) {
        e.preventDefault();

        if (window.confirm("Tem certeza de que deseja excluir o curso? A ação é irreversível.")) {
            api.delete(`/course/${course_id}`)
                .then(response => {
                    history.push("/admin/courses/all");
                }).catch(err => {
                    toast.warn(`Não foi possível deletar o curso. ${err}`, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                    });
                })
        }
    }

    if (loading)
        return (<div className="loaderDiv"> <Loader type="ThreeDots" color={'#863231'} /> </div>);

    return (
        <div className="registerCourse">
            {!course_id ? <div className="pageTitle">
                <span>
                    Registrar Curso
                </span>
            </div>

                :

                (isAdmin ?
                    <div className="pageTitle">
                        <span>
                            Editar Curso
                        </span>
                    </div>
                    : <div className="pageTitle">
                        <span>
                            Visualizar Curso
                        </span>
                    </div>

                )

            }
            <div className="pageContent">
                <form onSubmit={register}>
                    <label>Nome</label>
                    <input placeholder="Nome" disabled={!isAdmin} value={name} onChange={e => setName(e.target.value)} />

                    <label>Descrição</label>
                    <textarea placeholder="Descrição" disabled={!isAdmin} value={description} onChange={e => setDescription(e.target.value)} />

                    <label>Data de início</label>
                    <DatePicker placeholder="DD/MM/AA" disabled={!isAdmin} value={new Date(beginning_date)} onChange={setBeginning_date} locale="pt-BR"></DatePicker>

                    <label>Data de Término</label>
                    <div className="radio">
                        <div className="radioOption">
                            <input type="radio"
                                checked={hasEndingDate ? "checked" : ""}
                                disabled={!isAdmin}
                                name="ending_date"
                                value="true"
                                onChange={e => setHasEndingDate(true)}
                                placeholder="Nome"
                            />
                            <label>Sim</label>
                        </div>
                        <div className="radioOption">
                            <input type="radio"
                                checked={!hasEndingDate ? "checked" : ""}
                                disabled={!isAdmin}
                                name="ending_date"
                                value="false"
                                onChange={e => setHasEndingDate(false)}
                            />
                            <label>Não</label>
                        </div>
                    </div>

                    {hasEndingDate ?
                        <>
                            <DatePicker placeholder="DD/MM/AA" disabled={!isAdmin} value={new Date(ending_date)} onChange={setEnding_date} locale="pt-BR"></DatePicker>
                        </>
                        : null
                    }

                    <label>Orgão Responsavel</label>
                    <input placeholder="Nome do orgão" disabled={!isAdmin} value={responsibleBody} onChange={e => setResponsibleBody(e.target.value)} />

                    {course_id ?
                        <>
                            <label>Palestrante</label>
                            <p>{speaker.length > 0 ? speaker.map(spk => {
                                return (
                                    <div>
                                        {spk.name}
                                    </div>
                                )
                            }) : 'A ser definido'}</p>
                        </>
                        : null
                    }
                    <label>Carga Horária (opcional)</label>
                    <input placeholder="Carga horária" disabled={!isAdmin} value={participant_workload} onChange={e => setParticipant_workload(e.target.value)} />

                    <label>Adicionar informações ao verso do certificado?</label>
                    <div className="radio">
                        <div className="radioOption">
                            <input type="radio"
                                checked={hasTopics ? "checked" : ""}
                                name="addInformation"
                                disabled={!isAdmin}
                                value="true"
                                onChange={e => setHasTopics(true)}
                                placeholder="Nome"
                            />
                            <label>Sim</label>
                        </div>
                        <div className="radioOption">
                            <input type="radio"
                                checked={!hasTopics ? "checked" : ""}
                                name="addInformation"
                                disabled={!isAdmin}
                                value="false"
                                onChange={e => setHasTopics(false)}
                            />
                            <label>Não</label>
                        </div>
                    </div>

                    {hasTopics ?
                        <div className="topicsInput">
                            <div className="plusLabel">
                                <label>Tópicos</label>
                                <label> - {topicsLineCounter}/38 linhas</label>
                                {isAdmin ?
                                    <AiFillPlusCircle size={30} cursor={'pointer'} onClick={addTopic} />
                                    : null
                                }
                            </div>
                            {topicFields.map(field => {
                                return (field)
                            })}
                        </div>
                        : null
                    }


                    {isAdmin ?
                        <>
                            <div className="plusLabel">
                                <label>Logos</label>
                                {logoCounter < 6 ?
                                    <AiFillPlusCircle size={30} cursor={'pointer'} onClick={() => {
                                        if (logoCounter < 6)
                                            setLogoCounter(logoCounter + 1);
                                    }} /> 
                                    : null
                                }
                            </div>

                            {logoFields.map(field => {
                                return (field)
                            })}

                            <div className="plusLabel">
                                <label>Assinaturas</label>
                                {signatureCounter < 4 ?
                                    <AiFillPlusCircle size={30} cursor={'pointer'} onClick={() => {
                                        if (signatureCounter < 4)
                                            setSignatureCounter(signatureCounter + 1);
                                    }} />
                                    : null
                                }
                            </div>
                            {signatureFields.map(field => {
                                return (field)
                            })}
                            <div className="buttonGroup">
                                {course_id ?
                                    <>
                                        <button className="removeCourseButton" onClick={deleteCourse}>
                                            <img className="removeCourse" src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" alt="remove-league"></img>
                                        </button>
                                    </>
                                    : null
                                }
                                <input type="submit" value={course_id ? "Editar" : "Cadastrar"} />
                            </div>
                        </>
                        : null
                    }
                </form>
            </div>
        </div>
    )
};