import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import api from "../../services/api";
import "../../utils/axiosInterceptor";
import Cookies from "js-cookie";
import "./styles.css";
import Loader from "react-loader-spinner";
import jwt from "jsonwebtoken";
import ReactPaginate from "react-paginate";
import { AiOutlineFolderAdd } from "react-icons/ai";
import { BsPlusSquareFill, BsCheckBox } from "react-icons/bs";
import { Button } from "bootstrap";
import Modal from "../../components/Modal";
import SelectComponent from "../../components/Select";

export default function RenderCourses(props) {
  const user_id = props.user_id;
  const { renderAll } = useParams();
  const { folder_id } = useParams();
  const [coursesTotal, setCoursesTotal] = useState(0);
  const [courses, setCourses] = useState([]);
  const [search, setSearch] = useState("");
  const [folders, setFolders] = useState([]);
  const [selectedCourses, setSelectedCoureses] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedFolderToAdd, setSelectedFolderToAdd] = useState(null);
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const [page, setPage] = useState(0);
  const PER_PAGE = 10;
  const showDisplayPhone = window.innerWidth < 1024;

  //Este componente é usado em dois casos: para renderizar todos os cursos
  //e para renderizar os cursos de um usuario. Por isso o parametro renderAll
  useEffect(() => {
    console.log(renderAll);
    console.log(folder_id);

    setLoading(true);
    async function getTotalData() {
      const [countCoursesResponse, coursesResponse] = await Promise.all([
        api.get("/courseCount"),
        api.get("/course", {
          params: {
            page: page,
          },
        }),
      ]);

      setCoursesTotal(Math.ceil(countCoursesResponse.data[0].count / PER_PAGE));
      setCourses(coursesResponse.data.courses);
      setLoading(false);
    }

    async function getUserData() {
      const [countCoursesResponse, coursesResponse] = await Promise.all([
        api.get("/userCoursesCount", {
          params: {
            user_id: user_id ? user_id : JSON.parse(Cookies.get("User")).id,
          },
        }),
        api.get("/userCourses", {
          params: {
            user_id: user_id ? user_id : JSON.parse(Cookies.get("User")).id,
            page: page,
          },
        }),
      ]);

      setCoursesTotal(Math.ceil(countCoursesResponse.data[0].count / PER_PAGE));
      setCourses(coursesResponse.data.courses);
      setLoading(false);
    }
    async function getTotalDataSearch() {
      const [countCoursesResponse, coursesResponse] = await Promise.all([
        api.get("/searchCoursesCount", {
          params: {
            name: search,
          },
        }),
        api.get("/searchCourses", {
          params: {
            name: search,
            page,
          },
        }),
      ]);

      setCoursesTotal(Math.ceil(countCoursesResponse.data[0].count / PER_PAGE));
      setCourses(coursesResponse.data.courses);
      setLoading(false);
    }

    async function getUserDataSearch() {
      const [countCoursesResponse, coursesResponse] = await Promise.all([
        api.get("/searchUserCoursesCount", {
          params: {
            name: search,
            user_id: user_id ? user_id : JSON.parse(Cookies.get("User")).id,
          },
        }),
        api.get("/searchUserCourses", {
          params: {
            name: search,
            page,
            user_id: user_id ? user_id : JSON.parse(Cookies.get("User")).id,
          },
        }),
      ]);

      setCoursesTotal(Math.ceil(countCoursesResponse.data[0].count / PER_PAGE));
      setCourses(coursesResponse.data.courses);
      setLoading(false);
    }

    async function getFoldersData() {
      const [foldersResponse] = await Promise.all([api.get("/folder")]);
      setFolders(foldersResponse.data.folders);
    }

    async function getFolderTotalData() {
      const folderDataResponse = await api
        .get(`/folder/${folder_id}`, {
          params: {
            user_id: user_id ? user_id : JSON.parse(Cookies.get("User")).id,
          },
        })
        .then((res) => {
          setCourses(res.data.courses);
        })
        .catch((err) => {
          console.log(err);
          setCourses([]);
        });

      setLoading(false);
    }

    getFoldersData();

    if (folder_id) {
      getFolderTotalData();
    } else if (renderAll) {
      if (search) getTotalDataSearch();
      else getTotalData();
    } else {
      if (search) getUserDataSearch();
      else getUserData();
    }
    window.scrollTo(0, 0);
  }, [renderAll, page, user_id, search]);

  // Invoke when user click to request another page.
  function handlePageClick(event) {
    setPage(event.selected);
  }

  async function handleForm(e) {
    e.preventDefault();
    setPage(0);
    setSearch(document.querySelector("#pesquisar").value.trim());
  }

  function getCrrFolder() {
    const res = folders.find((value) => value.id == folder_id);
    return res;
  }

  return (
    <div className="courses">
      {!user_id ? (
        <div className="pageTitle">
          <div className="pageTitleLeft">
            {folder_id ? (
              <span>
                {getCrrFolder() ? getCrrFolder().name : "Folder não encontrado"}
              </span>
            ) : (
              <span>{renderAll ? "Todos os Cursos" : "Meus Cursos"}</span>
            )}

            {!folder_id ? (
              <form className="form" onSubmit={handleForm}>
                <input
                  type="text"
                  id="pesquisar"
                  name="pesquisar"
                  className="inputForm"
                />
                <button type="submit">Pesquisar</button>
              </form>
            ) : (
              <></>
            )}
          </div>
          <div className="pageTitleRight">
            {selectedCourses.length > 0 ? (
              <button
                onClick={() => {
                  setIsModalOpen(true);
                }}
              >
                Adicionar em uma Pasta
              </button>
            ) : (
              <div></div>
            )}
            <Modal isOpen={isModalOpen}>
              <div className="modal-content-div">
                <SelectComponent
                  options={folders}
                  selectedOption={selectedFolderToAdd}
                  setSelectedOption={setSelectedFolderToAdd}
                />
                <div className="modal-buttons-div">
                  <button
                    className="modal-button"
                    onClick={() => {
                      setIsModalOpen(false);
                    }}
                  >
                    Voltar
                  </button>
                  <button
                    className="modal-button"
                    onClick={() => {
                      if (selectedFolderToAdd) {
                        api
                          .post("/folder/addCourse", {
                            courses_ids: selectedCourses,
                            user_id: user_id
                              ? user_id
                              : JSON.parse(Cookies.get("User")).id,
                            folder_id: selectedFolderToAdd.id,
                          })
                          .then((res) => {
                            console.log(res.data)
                            setIsModalOpen(false)
                            setSelectedCoureses([])
                            setSelectedFolderToAdd(null)
                          })
                          .catch((err) => console.log(err));
                      }
                    }}
                  >
                    Adicionar
                  </button>
                </div>
              </div>
            </Modal>
          </div>
        </div>
      ) : null}
      <div className="pageContent">
        {loading ? (
          <div className="loaderDiv">
            {" "}
            <Loader type="ThreeDots" color={"#863231"} />{" "}
          </div>
        ) : courses.length !== 0 ? (
          <div className="coursesRender">
            {courses.map((course) => (
              <div className="card" id="cardCourse" key={`course${course.id}`}>
                <div className="card-header" id="cardHeaderCourse">
                  <span
                    onClick={() => {
                      history.push(`/user/course/${course.id}`);
                    }}
                  >
                    {course.name}
                  </span>
                </div>
                <div className="card-body" id="cardBodyCourse">
                  <span>{course.description}</span>
                </div>
                <div className="card-footer" id="cardFooterCourse">
                  <span
                    onClick={() => {
                      history.replace(
                        `/certification?code=${jwt.sign(
                          {
                            course_id: course.id,
                            user_id: user_id
                              ? user_id
                              : JSON.parse(Cookies.get("User")).id,
                          },
                          "secret1"
                        )}`
                      );
                    }}
                  >
                    Ver Certificado
                  </span>
                  <button className="card-button" id="cardButtonCourse">
                    {selectedCourses.find((value) => value == course.id) ? (
                      <BsCheckBox
                        size={45}
                        color="#54000A"
                        onClick={() => {
                          setSelectedCoureses(
                            selectedCourses.filter((item) => item !== course.id)
                          );
                        }}
                      />
                    ) : (
                      <BsPlusSquareFill
                        size={45}
                        color="#54000A"
                        onClick={() => {
                          setSelectedCoureses([...selectedCourses, course.id]);
                        }}
                      />
                    )}
                  </button>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <span>Não há mais cursos</span>
        )}
        {
          <div className="new-folder-div">
            {!folder_id ? (
              <button
                className="new-folder-button"
                onClick={async () => {
                  const folderName = prompt("Digite o nome da pasta");
                  await api
                    .post("/folder", { name: folderName })
                    .then((res) => {
                      const newFolder = {
                        name: folderName,
                        id: res.data.createdFolder.id,
                      };
                      setFolders([...folders, newFolder]);
                    });
                }}
              >
                <AiOutlineFolderAdd color="#54000A" size={30} />
                <p className="new-folder-text">Criar nova Pasta</p>
              </button>
            ) : (
              <div></div>
            )}
          </div>
        }

        {
          <div className="folders-div">
            {!folder_id ? (
              folders.map((value) => {
                return (
                  <div
                    className="folders-card"
                    onClick={async () => {
                      history.push(`/user/courses/${value.id}`);
                    }}
                  >
                    <p className="folders-text">{value.name}</p>
                    <p className="folders-number">(2)</p>
                  </div>
                );
              })
            ) : (
              <div></div>
            )}
          </div>
        }

        {courses.length !== 0 && (
          <div className="paginator">
            <ReactPaginate
              breakLabel={showDisplayPhone ? null : "..."}
              nextLabel={showDisplayPhone ? ">" : "next >"}
              onPageChange={handlePageClick}
              forcePage={page}
              pageRangeDisplayed={showDisplayPhone ? 2 : 6}
              pageCount={coursesTotal}
              className={showDisplayPhone ? "paginatorComponent" : null}
              previousLabel={showDisplayPhone ? "<" : "< previous"}
              renderOnZeroPageCount={null}
              pageClassName={
                showDisplayPhone ? "itemPaginateButton" : "page-item"
              }
              pageLinkClassName={
                showDisplayPhone ? "itemPaginateText" : "page-link"
              }
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              breakClassName="page-item"
              breakLinkClassName="page-link"
              containerClassName="pagination"
              activeClassName="active"
            />
          </div>
        )}
      </div>
    </div>
  );
}
