import React, { useState } from 'react';
import './styles.css'; // Importando o arquivo CSS

const SelectComponent = ({ options, setSelectedOption, selectedOption }) => {

  const handleSelectChange = (event) => {
    const selectedId = event.target.value;
    const selectedObject = options.find(option => option.id === parseInt(selectedId));
    setSelectedOption(selectedObject);
  };

  return (
    <div className="select-container">
      <h2 className="select-heading">Selecione uma opção</h2>
      <select 
        className="select-box" 
        onChange={handleSelectChange} 
        value={selectedOption ? selectedOption.id : ''}
      >
        <option value="" disabled>Escolha uma opção...</option>
        {options.map((option) => (
          <option key={option.id} value={option.id}>
            {option.name}
          </option>
        ))}
      </select>
    </div>
  );
};

export default SelectComponent;
