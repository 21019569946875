import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import './styles.css';
import api from '../../services/api';
import "../../utils/axiosInterceptor";
import './styles.css';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';
import { MdKeyboardBackspace } from 'react-icons/md';
import RenderCourses from '../RenderCourses';
import { checkUserInput } from '../../Validate/checkUser.js';
import NumberFormat from 'react-number-format';
import Loader from 'react-loader-spinner';


export default function Personal() {
    const currentUser = JSON.parse(Cookies.get('User'));
    const isAdminAux = JSON.parse(Cookies.get('User')).isAdmin;
    const { user_id } = useParams();
    const [name, setName] = useState();
    const [email, setEmail] = useState();
    const [cpf, setCpf] = useState();
    const [user, setUser] = useState();
    const [isAdmin, setIsAdmin] = useState();
    const [isDefaulter, setIsDefaulter] = useState();
    const [fromUniversity, setFromUniversity] = useState();
    const [league_id, setLeague_id] = useState(null);
    const [league_function, setLeague_function] = useState(null);
    const [allLeagues, setAllLeagues] = useState([]);
    const [loading, setLoading] = useState(true);
    const history = useHistory();

    //Obtem as informacoes do usuario
    useEffect(() => {
        setLoading(true);
        api.get(isAdminAux ? `/user${user_id ? '/' + user_id : '/0'}` : "/getUserByToken")
            .then(response => {
                setUser(response.data.user);
                setName(response.data.user.name);
                setEmail(response.data.user.email);
                setCpf(response.data.user.cpf);
                setIsAdmin(!!response.data.user.isAdmin);
                setIsDefaulter(!!response.data.user.isDefaulter);
                setFromUniversity(response.data.user.fromUniversity);
                setLeague_id(response.data.user.league_id);
                setLeague_function(response.data.user.league_function);
                api.get(`/league`)
                    .then(response => {
                        setAllLeagues(response.data.leagues);
                        setLoading(false);
                    })
            })
    }, [user_id, isAdminAux])



    //Funcao usada para alterar o conteudo da pagina de acordo com a selecao do menu
    function changeTab(tab) {
        const classes = ['userInformationsContent', 'userCourses'];

        classes.forEach(element => {
            document.getElementsByClassName(element)[0].style.display = 'none';
        });

        document.getElementsByClassName(tab)[0].style.display = 'grid';

    }

    //Chama uma rota para editar as informacoes do usuario
    function editUser(e) {
        e.preventDefault();

        let data;
        data = {
            user_id: user_id ? user_id : currentUser.id,
            name,
            email,
            cpf,
            isDefaulter,
            fromUniversity,
            league_id,
            league_function,
            isAdmin
        }
        var error = checkUserInput(data);
        if (error.length !== 0) {
            error.map(err => (
                toast.warn(err, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                })
            ));
        } else {
            api.put(isAdminAux ? "/user" : "/editOwnInformations", data)
                .then(response => {
                    toast.success("Informações alteradas com sucesso!", {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                    });
                    // eslint-disable-next-line
                    if (!user_id) {
                        Cookies.set('User', response.data.user);
                    }
                }).catch((err) => {
                    toast.warn("Alguma das novas informações colocadas não segue a formatação necessária para seu respectivo campo. Tente novamente.", {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                    });
                })
        }
    }

    function resetPassword(e){
        e.preventDefault();
        
        const email = currentUser.email;
        
        api.post("/user/forgottenPassword", {
            user_email: email
        })
            .then((response) => {
                toast.success(`Para trocar sua senha, siga as intruções enviadas para: ${email}`, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                });
            })
            .catch(err => {
                console.log(err);
            
                // Check if the error response exists
                if (err.response) {
                    // You can use err.response.data or err.response.status to provide more specific feedback
                    toast.warn(`Ocorreu um erro no servidor! ${err.response.data}`, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                    });
                } else {
                    // Handle cases where there is no response (network error, etc.)
                    toast.warn("Não foi possível conectar ao servidor!", {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                    });
                }
            });
            
        
    }

    function deleteUser(e) {
        e.preventDefault();

        if (window.confirm("Tem certeza de que deseja excluir o usuário? A ação é irreversível.")) {
            api.delete(`/user/${user_id}`)
                .then(response => {
                    history.push("/admin/user/all");
                }).catch(err => {
                    alert(`Não foi possível deletar o usuário. ${err}`);
                })
        }
    }

    if (loading)
        return (<div className="loaderDiv"> <Loader type="ThreeDots" color={'#863231'}/> </div>);

    return (
        <div className="userInformations">
            <div className="goBack">
                <MdKeyboardBackspace size={35} className="arrowBack" onClick={() => {
                    history.goBack();
                }} />
                {currentUser.id !== user.id ?
                    <ul>
                        <li className="userInformationsContentTab" onClick={() => {
                            changeTab('userInformationsContent');
                        }}>
                            <span>Informações</span>
                        </li>

                        <li className="userCoursesTab" onClick={() => {
                            changeTab('userCourses');
                        }}>
                            <span>Cursos</span>
                        </li>
                    </ul>
                    : null
                }
            </div>

            <div className="pageContent">
                <div className="pageTitle">
                    <span>
                        Perfil
                    </span>
                </div>
                <div className="userInformationsContent">
                    <form onSubmit={editUser}>
                        <label>Nome</label>
                        <input value={name} onChange={e => setName(e.target.value)} />
                        <label>Email</label>
                        <input value={email} onChange={e => setEmail(e.target.value)} />

                        <div className="cpfAndDefaulter">
                            <div className="labelInput">
                            <label>Cpf</label>
                            <NumberFormat value={cpf} format="###.###.###-##" onValueChange={(values) => {
                                setCpf(values.value);
                            }}></NumberFormat>
                            </div>

                            <div className="labelInput">
                                <label>Inadimplente</label>
                                <select onChange={e => setIsDefaulter(e.target.value === 'true')} disabled={!isAdminAux || isAdmin}>
                                    <option selected={isDefaulter ? "selected" : ""} value='true' >Sim</option>
                                    <option selected={!isDefaulter ? "selected" : ""} value='false'>Nao</option>
                                </select>
                            </div>
                        </div>

                        <label>Estudante</label>
                        <select id="fromUniversity" disabled={!isAdminAux} name="fromUniversity" onChange={e => setFromUniversity(e.target.value === 'true')}>

                            <option value='true' selected={fromUniversity ? "selected" : ""}>Estudante da Universidade</option>
                            <option value='false' selected={!fromUniversity ? "selected" : ""}>Estudante de fora da Universidade</option>

                        </select>

                        <label>Liga</label>
                        <select disabled={!isAdminAux} onChange={e => {
                            setLeague_id(parseInt(e.target.value) === 0 ? null : parseInt(e.target.value))
                            setLeague_function(null);
                        }}>
                            <option selected={league_id === null ? "selected" : ""} value={0} >Nenhuma</option>
                            {allLeagues.map(league => (
                                <option key={'leagueOption' + league.id} selected={league_id === league.id ? "selected" : ""} value={league.id}>{league.name}</option>
                            ))}
                        </select>
                        {league_id != null ?
                            <div style={{ display: "grid" }}>
                                <label>Cargo na liga</label>
                                <select disabled={!isAdminAux} onChange={e => setLeague_function(e.target.value)}>
                                    <option selected={league_function === 'nenhuma' ? "selected" : ""} value={"nenhuma"} >Nenhuma</option>
                                    <option selected={league_function === "lider" ? "selected" : ""} value={"lider"} >Lider</option>
                                </select>
                            </div>

                            : null
                        }
                        <button onClick={resetPassword}>Mudar Senha</button>
                        <input type="submit" value="Editar" />
                        <div className="buttonGroup">
                            {// eslint-disable-next-line
                            currentUser.id != user_id && (user_id)? 
                                <>
                                <button className="removeUserButtonn" onClick={deleteUser}>
                                    <img className="removeUser" src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" alt="remove-league"></img>
                                </button>
                                </>
                                : <div></div>
                            }
                        </div>
                    </form>
                </div>
            </div>
            <div className="userCourses">
                <RenderCourses user_id={user_id} />
            </div>
        </div>
    );
}