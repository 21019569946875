import * as validation from './validation';

//Verifica os campos do usuario
export function checkUserInput(input) {
    var message = [];
    if (!validation.validateName(input.name)) {
        message.push("Nome inválido (Deve conter apenas letras)");
    }
    if (!(validation.validateEmail(input.email)) && input.email.length > 0) {
        message.push("Email inválido");
    }

    //se for aluno da universidade, cpf obrigatorio
    if (!validation.validateCPF(input.cpf)) {
        message.push("CPF Inválido (CPF Obrigatório)");
    }
    return message;

}

//verifica os campos de um usuario ao registrar
export function checkUserRegisterInput(input) {
    // Mantém as validações de checkUserInput
    const message = checkUserInput(input);
    const password = input.password;
    const passwordMessages = validation.validadePassword(password);
    passwordMessages.forEach(msg => {
        message.push(msg);
    });

    return message;
}


//verifica os campos de login de um usuario
export function checkUserLogin(input) {
    var message = [];
    if (input.password.length < 8) {
        message.push("Senha inválida (Senha deve ter no mínimo 8 dígitos)");
    }
    if (!validation.validateEmail(input.email)) {
        message.push("Email Inválido");
    }
    return message;
}

