import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import api from '../../services/api';
import './styles.css'
import "../../utils/axiosInterceptor";
import { toast } from 'react-toastify';
import Loader from 'react-loader-spinner';
import { validadePassword } from '../../Validate/validation';


export default function ForgottenPassword() {
    const [password, setPassword] = useState('');
    const [passwordConfirmation, setPasswordConfirmation] = useState('');
    const [loading, setLoading] = useState(true);
    const {
        resetPasswordToken} = useParams();
    const history = useHistory();

    useEffect(() => {
        setLoading(true);
        api.post('/user/verifyResetPasswordToken', {resetPasswordToken})
        .then(() => {
            setLoading(false);
        })
        .catch(error => {
            toast.error('Link inválido ou expirou', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
            });
            history.push('/login');
        })
    }, [resetPasswordToken, history])

    function resetPassword(e){
        e.preventDefault();

        var error = validadePassword(password)
        console.log(error);

        if( password !== passwordConfirmation){
            toast.error('As senhas não coincidem', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
            });
        }
        else if (error.length !== 0) {
            toast.error(error[0], {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
            });
        }
        else{
            api.post('/user/resetPassword', {
                newPassword: password,
                resetPasswordToken: resetPasswordToken
            })
            .then(reponse => {
                toast.success('Senha alterada com sucesso', {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                });
                history.push('/login');
            })
            .catch(err => {
                toast.error('Um erro ocorreu', {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                });
                history.push('/login');
            })
        }
    }

    if(loading)
        return (<div className="loaderDiv"> <Loader type="ThreeDots" color={'#863231'}/> </div>);

    return (
        <div className="ResetPasswordFields">
            <form onSubmit={resetPassword}>
                <h1>Resetar minha senha</h1>
                <input
                    placeholder="Nova senha"
                    type="password"
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                />
                <input
                    placeholder="Confirmação da nova senha"
                    type="password"
                    value={passwordConfirmation}
                    onChange={e => setPasswordConfirmation(e.target.value)}
                />
                <button className="sendButton" type="submit">Enviar</button>
            </form>
        </div>
    )
}
